import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import styles from "./ScoreCard.module.css";

interface ScorecardDataTableProps {
  isNineHoles?: boolean;
  scoreCardData: any;
  isLoading: boolean;
  isFront: boolean;
  currentHoleNumber: number;
  currentShotNumber: number;
  setCurrentShotNumber: (currentShot: number) => void;
  setCurrentHoleNumber: (currentShot: number) => void;
  setCurrentVideo: (currentVideo: string | undefined) => void;
}

interface HoleData {
  grossScore: number;
  par: number;
  holeNumber: number;
  shots: [];
  distance: number;
}

const ScorecardDataTable = ({
  isNineHoles,
  scoreCardData,
  isLoading,
  isFront,
  currentShotNumber,
  currentHoleNumber,
  setCurrentHoleNumber,
  setCurrentVideo,
  setCurrentShotNumber,
}: ScorecardDataTableProps) => {
  const videoData = scoreCardData?.data?.data?.node?.holes;

  const handleHoleClick = ({ holeNumber }: { holeNumber: number }) => {
    setCurrentHoleNumber(holeNumber);
    if (currentShotNumber !== 1) {
      setCurrentShotNumber(1);
    }
    const getVideo = videoData?.find(
      (video: { holeNumber: number }) => video.holeNumber === holeNumber
    );
    if (getVideo && getVideo.shots && getVideo.shots[0].videos?.length) {
      setCurrentVideo(getVideo.shots[0].videos[0].videoUrl);
    } else {
      setCurrentVideo(undefined);
    }
  };

  const isNineHoleRound =
    isNineHoles || scoreCardData?.data?.data?.node?.holes?.length === 9;

  let holeStartIndex = 0;
  let holeEndIndex = 9;

  if (!isFront && !isNineHoleRound) {
    holeStartIndex = 9;
    holeEndIndex = 18;
  }

  let yards = 0;
  let par = 0;
  let score = 0;
  let totalYards = 0;
  let totalPar = 0;
  let totalScore = 0;

  scoreCardData?.data?.data?.node?.holes.forEach(
    (hole: HoleData, index: number) => {
      if (isFront && index < 9) {
        yards += Math.floor(hole.distance * 1.09361);
        par += hole.par;
        score += hole.grossScore;
      }
      if (!isFront && index >= 9) {
        yards += Math.floor(hole.distance * 1.09361);
        par += hole.par;
        score += hole.grossScore;
      }
      totalYards += Math.floor(hole.distance * 1.09361);
      totalPar += hole.par;
      totalScore += hole.grossScore;
    }
  );

  return (
    <div
      className={`${styles.scoreCard}${
        !isLoading
          ? isFront
            ? " " + styles.scoreCardFront
            : " " + styles.scoreCardBack
          : ""
      }
      ${
        isFront && isNineHoleRound ? " " + styles.scoreCardFrontNineRound : ""
      }${
        !isFront && isNineHoleRound ? " " + styles.scoreCardBackNineRound : ""
      }`}
    >
      <div
        className={`${styles.scoreCardCol} ${styles.scoreCardColHighlight} ${
          isFront ? styles.scoreCardColFront : styles.scoreCardColBack
        }`}
      >
        <div>Hole</div>
        <div>Yds</div>
        <div>Par</div>
        <div>Total</div>
      </div>
      {isLoading &&
        Array.from(Array(9)).map((_item: any, i: number) => (
          <div className={styles.scoreCardCol} key={i}>
            <div>
              <span className={styles.loading} />
            </div>
            <div>
              <span className={styles.loading} />
            </div>
            <div>
              <span className={styles.loading} />
            </div>
            <div>
              <span>
                <span>
                  <span className={styles.loading} />
                </span>
              </span>
            </div>
          </div>
        ))}
      {scoreCardData?.data?.data?.node?.holes
        ?.slice(holeStartIndex, holeEndIndex)
        ?.map((hole: HoleData) => {
          const legendClasses = [styles.legend];
          if (hole.grossScore && hole.grossScore === hole.par - 1) {
            legendClasses.push(styles.legendBirdie);
          }
          if (hole.grossScore && hole.grossScore < hole.par - 1) {
            legendClasses.push(styles.legendEagle);
          }
          if (hole.grossScore && hole.grossScore === hole.par + 1) {
            legendClasses.push(styles.legendBogey);
          }
          if (hole.grossScore && hole.grossScore > hole.par + 1) {
            legendClasses.push(styles.legendDBogey);
          }
          const renderLegendClasses = legendClasses.join(" ");
          return (
            <div
              className={`${styles.scoreCardCol}${
                currentHoleNumber === hole.holeNumber
                  ? " " + styles.scoreCardColSelected
                  : ""
              }`}
              onClick={() => {
                handleHoleClick({ holeNumber: hole.holeNumber });
              }}
              key={hole.holeNumber}
            >
              <div className={styles.scoreCardHoleNumber}>
                <span>{hole.holeNumber}</span>
                {hole?.shots?.find((shot: { videos: [] }) => {
                  return !!shot?.videos?.length;
                }) && (
                  <PlayCircleOutlineIcon
                    className={styles.scorCardHoleNumberIcon}
                  />
                )}
              </div>
              <div>{Math.floor(hole.distance * 1.09361)}</div>
              <div>{hole.par}</div>
              <div>
                <span className={renderLegendClasses}>
                  <span>{hole.grossScore || "-"}</span>
                </span>
              </div>
            </div>
          );
        })}
      <div className={`${styles.scoreCardCol} ${styles.scoreCardColHighlight}`}>
        <div>{isFront ? "Out" : "In"}</div>
        <div>{isLoading ? <span className={styles.loading} /> : yards}</div>
        <div>{isLoading ? <span className={styles.loading} /> : par}</div>
        <div>{isLoading ? <span className={styles.loading} /> : score}</div>
      </div>
      {isFront && !isNineHoleRound && (
        <div className={`${styles.scoreCardCol} ${styles.scoreCardColEnd}`}>
          <div />
          <div />
          <div />
          <div>
            <span>-</span>
          </div>
        </div>
      )}
      {(!isFront || isNineHoleRound) && (
        <div className={styles.scoreCardCol}>
          <div>Total</div>
          <div>
            {isLoading ? <span className={styles.loading} /> : totalYards}
          </div>
          <div>
            {isLoading ? <span className={styles.loading} /> : totalPar}
          </div>
          <div>
            {isLoading ? <span className={styles.loading} /> : totalScore}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScorecardDataTable;
