import { useState } from "react";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import Leaderboard from "../Leaderboard/Leaderboard";
import styles from "./LeaderboardWrapper.module.css";
import { Option } from "../Dropdown/Dropdown";
import { isRoundNumber456 } from "../../utils/tournaments";
import LeaderboardActionBar from "./LeaderboardActionBar";
// import FinaleRoundLeaderboard from "../Leaderboard/FinaleRoundLeaderboard";

export type TournamentQuery =
  | "closestToPinLeaderboard"
  | "greensInRegulation"
  | "amLeaderboard"
  | "leaderboard"
  | "aggregatedClosestToPinLeaderboard"
  | "birdieStreak"
  | "longestDrive";

export type Gender = "MALE" | "FEMALE";

interface LeaderboardWrapperProps {
  tournamentId: string;
  tournamentName?: string;
  isAmateur?: boolean;
  leaderboardSelectOptions?: [];
  ctpSelectOptions?: Array<number>;
  isNowPlaying?: boolean;
  // isFinale?: boolean;
  nationalitiesOptions?: Array<{ label: string; value: string | null }>;
  isPGATournament?: boolean;
  tournamentSeason: number;
  startDate: string;
}

export interface QueryVariablesType {
  playNow?: boolean;
  nationality?: string[];
  searchText?: string;
  gender?: Gender;
  participantGroupId?: string;
  kind?: string;
  holeNumber?: number;
}

interface BoardType {
  id: number;
  groupId?: string;
  label: string;
  tQuery: TournamentQuery;
  tQueryKey: string;
  tQueryVariables: QueryVariablesType;
}

const LeaderboardWrapper = ({
  tournamentId,
  tournamentName,
  isAmateur,
  leaderboardSelectOptions,
  ctpSelectOptions,
  isNowPlaying,
  // isFinale,
  nationalitiesOptions,
  isPGATournament,
  tournamentSeason,
  startDate,
}: LeaderboardWrapperProps) => {
  const [tournamentQueryKey, setTournamentQueryKey] = useState<number | string>(
    1
  );
  const [tournamentQuery, setTournamentQuery] = useState<TournamentQuery>(
    isAmateur ? "amLeaderboard" : "leaderboard"
  );
  const [queryVariables, setQueryVariables] = useState({});
  const [selectedBoard, setSelectedBoard] = useState(1);
  const [selectedCTPBoard, setSelectedCTPBoard] = useState(0);

  const handleApplyFilters = (filters: object) => {
    setQueryVariables({ ...queryVariables, ...filters });
  };

  const amCtpOptions =
    ctpSelectOptions && leaderboardSelectOptions
      ? ctpSelectOptions.map((holeNumber, index) => ({
          id: leaderboardSelectOptions.length + 1 + index,
          label: `CTP: Hole ${holeNumber}`,
          tQuery: "closestToPinLeaderboard" as TournamentQuery,
          tQueryKey: `ctp-${holeNumber}_`,
          tQueryVariables: {
            holeNumber,
          },
        }))
      : [];

  const ctpOptions = ctpSelectOptions
    ? ctpSelectOptions.map((holeNumber, index) => ({
        id: 1 + index,
        label: `CTP: Hole ${holeNumber}`,
        tQuery: "closestToPinLeaderboard" as TournamentQuery,
        tQueryKey: `ctp-${holeNumber}_`,
        tQueryVariables: {
          holeNumber,
        },
      }))
    : [];

  const selectOptions: BoardType[] =
    isAmateur && leaderboardSelectOptions
      ? [
          ...leaderboardSelectOptions.map(
            (group: { id: string; name: string }, index: number) => ({
              id: index + 1,
              groupId: group.id,
              label: group.name,
              tQuery: "amLeaderboard" as TournamentQuery,
              tQueryKey: `${index + 1}`,
              tQueryVariables: {
                participantGroupId: group.id,
              },
            })
          ),
          ...amCtpOptions,
        ]
      : [
          {
            id: 1,
            label: "Stroke play",
            tQuery: "leaderboard" as TournamentQuery,
            tQueryKey: "",
            tQueryVariables: {},
          },
          {
            id: 2,
            label: "Closest to the pin",
            tQuery: "aggregatedClosestToPinLeaderboard" as TournamentQuery,
            tQueryKey: "ctp_",
            tQueryVariables: {},
          },
          {
            id: 3,
            label: "Birdie streak",
            tQuery: "birdieStreak",
            tQueryKey: "birdie_" as TournamentQuery,
            tQueryVariables: {
              kind: "BIRDIE_STREAK",
            },
          },
          {
            id: 4,
            label: "Longest drive - men",
            tQuery: "longestDrive" as TournamentQuery,
            tQueryKey: "l_drive_men_",
            tQueryVariables: {
              gender: "MALE",
            },
          },
          {
            id: 5,
            label: "Longest drive - women",
            tQuery: "longestDrive" as TournamentQuery,
            tQueryKey: "l_drive_women_",
            tQueryVariables: {
              gender: "FEMALE",
            },
          },
          {
            id: 6,
            label: "Most GIR",
            tQuery: "greensInRegulation" as TournamentQuery,
            tQueryKey: "gir_",
            tQueryVariables: {
              kind: "GREENS_IN_REGULATION",
            },
          },
        ];

  const handleSelectChange = (board: BoardType) => {
    if (board.id !== selectedBoard) {
      setTournamentQuery(board.tQuery);
      setSelectedBoard(board.id);
      setQueryVariables(board.tQueryVariables);
      setTournamentQueryKey(board.tQueryKey);
    }
  };

  const handleCTPSelectChange = (board: BoardType) => {
    if (board.id !== selectedCTPBoard) {
      setTournamentQuery(board.tQuery);
      setSelectedCTPBoard(board.id);
      setQueryVariables(board.tQueryVariables);
      setTournamentQueryKey(board.tQueryKey);
    }
  };

  const dropdownOptions = () => {
    if (tournamentSeason === 3) {
      return isPGATournament
        ? [
            {
              id: 1,
              label: "Stroke play",
              tQuery: "leaderboard" as TournamentQuery,
              tQueryKey: "",
              tQueryVariables: {},
            },
            {
              id: 2,
              label: "Closest to the pin",
              tQuery: "closestToPinLeaderboard" as TournamentQuery,
              tQueryKey: `ctp-${ctpSelectOptions && ctpSelectOptions[0]}_`,
              tQueryVariables: {
                holeNumber:
                  ctpSelectOptions && ctpSelectOptions.length > 0
                    ? ctpSelectOptions[0]
                    : null,
              },
            },
          ]
        : selectOptions.filter(({ id }) => id !== 3 && id !== 6);
    }
    if (tournamentSeason !== 3) {
      if (isRoundNumber456(tournamentName as string)) {
        return selectOptions.slice(0, selectOptions.length - 1);
      }

      if (tournamentName?.toLowerCase()?.includes("final")) {
        return selectOptions.slice(0, 2);
      }
    }

    return selectOptions;
  };

  return (
    <GridWrapper className={styles.root}>
      <GridElement
        gridLayout={[
          [961, 2, 14],
          [1401, 4, 26],
        ]}
      >
        <LeaderboardActionBar
          nationalitiesOptions={nationalitiesOptions}
          defaultDropdownOption={selectOptions[0] as Option}
          dropdownOptions={dropdownOptions()}
          isNowPlaying={!!isNowPlaying}
          startDate={startDate}
          tournamentQuery={tournamentQuery}
          onSelectChange={handleSelectChange}
          onApplyFilters={handleApplyFilters}
          tournamentName={tournamentName}
          isCTPLeaderboard={selectedBoard === 2}
          ctpDefaultDropdownOption={
            !isPGATournament
              ? ({
                  id: 0,
                  label: "Aggregated",
                  tQuery:
                    "aggregatedClosestToPinLeaderboard" as TournamentQuery,
                  tQueryKey: "ctp_",
                  tQueryVariables: {},
                } as Option)
              : (ctpOptions[0] as Option)
          }
          ctpDropdownOptions={
            !isPGATournament
              ? [
                  {
                    id: 0,
                    label: "Aggregated",
                    tQuery:
                      "aggregatedClosestToPinLeaderboard" as TournamentQuery,
                    tQueryKey: "ctp_",
                    tQueryVariables: {},
                  } as Option,
                  ...ctpOptions,
                ]
              : ctpOptions
          }
          onCTPSelectChange={handleCTPSelectChange}
        />
      </GridElement>
      <GridElement
        className={styles.leaderboard}
        gridLayout={[
          [961, 2, 14, 2],
          [1401, 4, 26, 2],
        ]}
      >
        {/* {isFinale ? (
          <FinaleRoundLeaderboard
            isAmateur={isAmateur}
            tournamentId={tournamentId}
            tournamentQueryKey={tournamentQueryKey}
            tournamentQuery={tournamentQuery}
            queryVariables={queryVariables}
            tournamentName={tournamentName}
          />
        ) : ( */}
        <Leaderboard
          isAmateur={isAmateur}
          tournamentId={tournamentId}
          tournamentQueryKey={tournamentQueryKey}
          tournamentQuery={tournamentQuery}
          queryVariables={queryVariables}
          tournamentName={tournamentName}
        />
        {/* )} */}
      </GridElement>
    </GridWrapper>
  );
};

export default LeaderboardWrapper;
