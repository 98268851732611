import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Image from "next/image";
import { useState } from "react";
import { motion } from "framer-motion";
import ShareIcon from "@mui/icons-material/Share";
import ScoreCardVideo from "../ScoreCardVideo/ScoreCardVideo";
import profileMan from "../../public/icons/avatar_m.svg";
import profileWoman from "../../public/icons/avatar_w.svg";
import styles from "./ScoreCard.module.css";
import { useRouter } from "next/router";
import { useWindowSize } from "../../hooks/useWindowSize";
import ScorecardDataTable from "./ScorecardDataTable";

interface ScoreCardProps {
  playerId: string;
  playerScoreCardId: string;
  shouldIntervalRefetch: boolean;
  profilePicture: string | null;
  playerName: string;
  playerHcp: string;
  playerGender: string;
  fetchData: boolean;
  isOpen?: boolean;
  setHasScorecardLoaded?: (hasLoaded: boolean) => void;
  playerMuted?: boolean;
  showVideoPlaceholder?: boolean;
  isNineHoles?: boolean;
  isAmateur?: boolean;
}

const shareMessageVariants = {
  initial: {
    opacity: 0,
  },
  active: {
    opacity: [1, 0],
    transition: {
      duration: 3,
    },
  },
};

const ScoreCard = ({
  playerId,
  playerScoreCardId,
  shouldIntervalRefetch,
  profilePicture,
  playerName,
  playerHcp,
  playerGender,
  fetchData = false,
  isOpen = false,
  playerMuted,
  showVideoPlaceholder,
  setHasScorecardLoaded,
  isNineHoles,
  isAmateur,
}: ScoreCardProps) => {
  const { isMobile } = useWindowSize();
  const [currentHoleNumber, setCurrentHoleNumber] = useState(1);
  const [currentShotNumber, setCurrentShotNumber] = useState(1);
  const [currentVideo, setCurrentVideo] = useState<string | undefined>(
    undefined
  );
  const [linkCopied, setLinkCopied] = useState(false);

  const { asPath } = useRouter();

  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const { data: scoreCardData, isLoading } = useQuery(
    ["player-scorecard-data", playerScoreCardId],
    () => {
      return axios.post("/api/get-scorecard", {
        variables: {
          scorecardId: playerScoreCardId,
        },
      });
    },
    {
      enabled: fetchData,
      refetchInterval: shouldIntervalRefetch && 1000 * 30, // 30s
      refetchIntervalInBackground: shouldIntervalRefetch,
      refetchOnWindowFocus: false,
      onSuccess: (scData) => {
        const getVideo = scData?.data?.data?.node?.holes[0];
        // check if initial hole is 1 for front nine or full 18 holes
        // or if playing only back nine and initial hole is 10.
        if (
          currentHoleNumber === 1 &&
          scData?.data?.data?.node?.holes[0].holeNumber !== 1
        ) {
          setCurrentHoleNumber(scData?.data?.data?.node?.holes[0].holeNumber);
        }
        if (getVideo && getVideo.shots && getVideo.shots[0].videos) {
          setCurrentVideo(getVideo.shots[0].videos[0]?.videoUrl);
        }
        if (setHasScorecardLoaded) {
          setHasScorecardLoaded(true);
        }
      },
    }
  );

  const videoData = scoreCardData?.data?.data?.node?.holes;

  return (
    <>
      <div className={styles.playerProfile}>
        <div className={styles.playerDetails}>
          <div className={styles.playerPicture}>
            {profilePicture ? (
              <Image
                src={profilePicture}
                alt={playerName || "Profile picture"}
                layout="fill"
                objectFit="cover"
              />
            ) : (
              <Image
                src={
                  playerGender?.toLowerCase() === "female"
                    ? profileWoman
                    : profileMan
                }
                alt={playerName || "Profile picture"}
                layout="fill"
                objectFit="cover"
              />
            )}
          </div>

          <div
            className={`${styles.playerInfo}${
              isLoading ? " " + styles.playerInfoLoading : ""
            }`}
          >
            <h3>{playerName}</h3>
            <p>
              <span>HCP</span>
              <span>
                {parseFloat(playerHcp) < 0
                  ? `+${-1 * parseFloat(playerHcp)}`
                  : playerHcp}
              </span>
            </p>
            <p>
              Tee:
              {isLoading ? (
                <span
                  style={{ height: "1.8rem", width: "4rem" }}
                  className={styles.loading}
                />
              ) : (
                <span>{scoreCardData?.data?.data?.node?.player?.tee}</span>
              )}
            </p>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <div className={styles.shareButtonWrapper}>
            <div
              role="button"
              className={styles.scorecardButton}
              onClick={() => {
                const basePath = asPath.split("?");
                navigator.clipboard.writeText(
                  `${origin}${basePath[0]}?playerid=${playerId}`
                );
                setLinkCopied(true);
              }}
              onMouseLeave={() => setLinkCopied(false)}
            >
              <ShareIcon style={{ fontSize: isMobile ? "large" : "medium" }} />
              {!isMobile && <span>Share</span>}
            </div>
            <motion.span
              variants={shareMessageVariants}
              animate={linkCopied ? "active" : "initial"}
              initial="initial"
              exit="initial"
              className={styles.shareMessage}
              onAnimationComplete={() => {
                if (linkCopied) {
                  setLinkCopied(false);
                }
              }}
            >
              Link copied
            </motion.span>
          </div>
        </div>
      </div>

      <div className={styles.scoreCardWrapper}>
        <div className={styles.scoreCardLegend}>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendEagle}`}>
              <span />
            </span>
            <span>Eagle or better</span>
          </div>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendBirdie}`} />
            <span>Birdie</span>
          </div>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendBogey}`} />
            <span>Bogey</span>
          </div>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendDBogey}`}>
              <span />
            </span>
            <span>Double Bogey+</span>
          </div>
        </div>

        <div className={styles.scoreCardContainer}>
          <ScorecardDataTable
            scoreCardData={scoreCardData}
            isLoading={isLoading}
            currentHoleNumber={currentHoleNumber}
            currentShotNumber={currentShotNumber}
            setCurrentShotNumber={setCurrentShotNumber}
            setCurrentHoleNumber={setCurrentHoleNumber}
            setCurrentVideo={setCurrentVideo}
            isFront={scoreCardData?.data?.data?.node?.holes[0].holeNumber === 1}
          />
          {!isNineHoles && (
            <ScorecardDataTable
              isNineHoles={isNineHoles}
              scoreCardData={scoreCardData}
              isLoading={isLoading}
              currentHoleNumber={currentHoleNumber}
              currentShotNumber={currentShotNumber}
              setCurrentShotNumber={setCurrentShotNumber}
              setCurrentHoleNumber={setCurrentHoleNumber}
              setCurrentVideo={setCurrentVideo}
              isFront={false}
            />
          )}
        </div>
      </div>

      <div className={styles.videoContainer}>
        {!currentVideo && !isLoading && showVideoPlaceholder && !isAmateur && (
          <div className={styles.videoPlaceholder}>
            <div className={styles.videoPlaceholderContent}>
              <h2>Selected hole has no video available</h2>
            </div>
          </div>
        )}
        {!currentVideo && !isLoading && showVideoPlaceholder && isAmateur && (
          <div className={styles.videoContainerAmateur} />
        )}
        {currentVideo && (
          <ScoreCardVideo
            playerMuted={playerMuted}
            isScoreCardOpen={isOpen}
            videoData={videoData}
            currentHoleNumber={currentHoleNumber}
            currentShotNumber={currentShotNumber}
            setCurrentShotNumber={setCurrentShotNumber}
            setCurrentHoleNumber={setCurrentHoleNumber}
            setCurrentVideo={setCurrentVideo}
          />
        )}
      </div>
    </>
  );
};

export default ScoreCard;
