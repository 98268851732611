import axios from "axios";
import { NEXT_PUBLIC_YOUTUBE_API_KEY } from "./constants";

export const fetchYoutubeVideos = async (
    playlistId: string,
    playlistTemplate: number,
    pageToken: string | null = null
) => {
    try {
        const playlistItemsUrl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=${playlistTemplate}${pageToken ? `&pageToken=${pageToken}` : ""
            }&key=${NEXT_PUBLIC_YOUTUBE_API_KEY}`;

        const playlistResponse = await axios.get(playlistItemsUrl);
        const videoIds = playlistResponse.data.items.map(
            (item: { snippet: { resourceId: { videoId: string } } }) => item.snippet.resourceId.videoId
        );

        // Fetch video details (e.g., view count) for each video
        const videoDetailsUrl = `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${videoIds.join(
            ","
        )}&key=${NEXT_PUBLIC_YOUTUBE_API_KEY}`;
        const videoDetailsResponse = await axios.get(videoDetailsUrl);
        const videoDetails = videoDetailsResponse.data.items;
        // Map videos with details
        const videos = playlistResponse.data.items.map((item: any) => {
            const videoDetail = videoDetails.find(
                (video: { id: string }) => video.id === item.snippet.resourceId.videoId
            );

            return {
                url: `https://www.youtube.com/watch?v=${item?.snippet?.resourceId?.videoId}`,
                title: item?.snippet?.title,
                channelTitle: item?.snippet?.channelTitle,
                thumbnail:
                    item?.snippet?.thumbnails?.maxres?.url ||
                    item?.snippet?.thumbnails?.default?.url,
                publishedAt: item?.snippet?.publishedAt,
                viewCount: videoDetail?.statistics?.viewCount,
            };
        });

        return {
            videos,
            nextPageToken: playlistResponse.data.nextPageToken || null,
        };
    } catch (error) {
        console.error("Error fetching YouTube videos:", error);

        return {
            videos: [],
            nextPageToken: null,
        };
    }



};
