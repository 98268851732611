import Link from "next/link";
import styles from "./Tabs.module.css";
import { useState, ReactNode } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { useWindowSize } from "../../hooks/useWindowSize";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import Dropdown from "../Dropdown/Dropdown";
import { Tab } from "../../types/tabs";

interface TabsProps {
  tabs: Tab[];
  logoLink?: string;
  logoTitle?: string;
  pageSlug: string;
  logoClassName?: string;
  logoWrapperClassName?: string;
  defaultLogo?: ReactNode;
  children?: ReactNode;
}

const Tabs = ({
  tabs,
  logoLink,
  logoTitle,
  pageSlug,
  logoClassName,
  logoWrapperClassName,
  defaultLogo,
  children,
}: TabsProps) => {
  const { asPath, push: routerPush } = useRouter();
  const { isMobile } = useWindowSize();
  const isHeader = logoTitle || logoLink;

  const logoWrapperClasses = [styles.tabLogoWrapper];
  if (logoWrapperClassName) {
    logoWrapperClasses.push(logoWrapperClassName);
  }
  const renderLogoWrapperClasses = logoWrapperClasses.join(" ");

  /* pageSlug is needed to be checked in order to render right url on refresh and load page  */
  const slugFromUrl = asPath
    .split("/")
    .filter((str) => !!str && !pageSlug?.split("/").includes(str))
    .join("");

  const tabBySlug = tabs?.find((tab: Tab) => tab?.slug === slugFromUrl);

  const [selectedTab, setSelectedTab] = useState<string>(
    tabBySlug?.value || tabs?.[0]?.value
  );

  const onClickSelect = (tab: Tab) => {
    setSelectedTab(tab?.value);
    routerPush({
      pathname: `/${pageSlug}/${
        pageSlug?.split("/").includes(tab?.slug) ? "" : tab?.slug
      }`,
    });
  };

  return (
    <GridWrapper>
      <GridElement gridLayout={[[1401, 4, 26]]}>
        {isHeader && (
          <div className={styles.tabsHeaderLogoWrapper}>
            {!!logoLink && (
              <div className={renderLogoWrapperClasses}>
                <div className={logoClassName}>
                  <Image
                    src={logoLink}
                    layout="fill"
                    objectFit="contain"
                    alt={logoTitle || "tab logo"}
                  />
                </div>
              </div>
            )}
            {!logoLink && defaultLogo}
            <h1 className={styles.tabLogoTitle}>{logoTitle}</h1>
            <span className={styles.orangeBorder} />
          </div>
        )}
        {isMobile && tabs?.length > 0 ? (
          <div className={styles.tabsDropdownWrapper}>
            <Dropdown
              className={styles.tabsDropdown}
              defaultValue={tabs?.find((t: Tab) => t?.value === selectedTab)}
              onChange={(tab: any) => onClickSelect(tab)}
              options={tabs}
            />
          </div>
        ) : (
          tabs?.length > 0 && (
            <div className={styles.tabs}>
              {tabs?.map((tab: Tab, index: number) => (
                <Link
                  key={index}
                  href={`/${pageSlug}/${
                    pageSlug?.split("/").includes(tab?.slug) ? "" : tab?.slug
                  }`}
                  as={`/${pageSlug}/${
                    pageSlug?.split("/").includes(tab?.slug) ? "" : tab?.slug
                  }`}
                  passHref
                >
                  <a
                    onClick={() => setSelectedTab(tab?.value)}
                    className={`${styles.tab} ${
                      tab?.value === selectedTab ? styles.tabActive : ""
                    }`}
                  >
                    {tab?.label}
                  </a>
                </Link>
              ))}
              {children}
            </div>
          )
        )}
      </GridElement>
    </GridWrapper>
  );
};

export default Tabs;
