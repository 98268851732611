import { sbBlok } from "../../types/sb-types";
import TournamentCard from "./TournamentCard";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./TournamentCardOverview.module.css";
import Cta from "../Cta/Cta";
import { motion } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { Tournament } from "../../types/tournament";

interface TournamentCardOverviewProps {
  blok: sbBlok;
  tournaments: Array<Tournament>;
}

const TournamentCardOverview = ({
  blok,
  tournaments,
}: TournamentCardOverviewProps) => {
  const { isDesktop } = useWindowSize();
  const tournamentsToShow = tournaments?.slice(0, 3);

  if (tournamentsToShow?.length === undefined) {
    return null;
  }

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);
  if (isFirstRender) return <div />;

  return (
    <GridWrapper
      padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
      padding_bottom={
        blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
      }
      gridClassName={styles.tournamentOverviewGridWrapper}
    >
      <GridElement gridLayout={[[1401, 4, 26]]}>
        <div>
          <h1 className={styles.blogPostsTitle}>{blok.title}</h1>
          <motion.div
            viewport={{ once: false }}
            initial={{
              opacity: isDesktop ? 1 : 0.3,
              y: isDesktop ? 0 : 60,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: "linear",
              duration: 0.8,
            }}
            className={styles.tournamentOverviewLayout}
          >
            {tournamentsToShow?.map((tournament: Tournament) => (
              <TournamentCard key={tournament.id} tournament={tournament} />
            ))}
          </motion.div>
          {blok.cta_link[0]?.link?.url && (
            <motion.div
              viewport={{ once: false }}
              initial={{
                y: isDesktop ? 0 : 60,
              }}
              whileInView={{
                y: 0,
              }}
              transition={{
                ease: "linear",
                duration: 0.8,
              }}
            >
              <Cta
                className={styles.buttonMargin}
                href={blok.cta_link[0].link.url}
                fullSlug={blok.cta_link[0]?.link?.story?.full_slug}
                name={blok.cta_link[0]?.name}
              />
            </motion.div>
          )}
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default TournamentCardOverview;
