import { ReactNode, useEffect, useState } from "react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Link from "next/link";
import Image from "next/image";
import { Squash as Hamburger } from "hamburger-react";
import { motion, AnimatePresence } from "framer-motion";
import twitterIcon from "../../public/icons/twitter.svg";
const FacebookIcon = dynamic(() => import("@mui/icons-material/Facebook"));
const InstagramIcon = dynamic(() => import("@mui/icons-material/Instagram"));
const HelpOutlineOutlinedIcon = dynamic(
  () => import("@mui/icons-material/HelpOutlineOutlined")
);
const YouTubeIcon = dynamic(() => import("@mui/icons-material/YouTube"));
import { useWindowSize } from "../../hooks/useWindowSize";
import GridWrapper from "../GridWrapper";
import styles from "./Layout.module.css";
import { Theme } from "../../types/theme";

interface LayoutProps {
  children: ReactNode;
  navLinks: { slug: string; name: string }[];
  amateurNavLinks: { slug: string; name: string }[];
  theme: Theme;
}

const mobileNavVariants = {
  initial: {
    y: 0,
  },
  open: {
    y: 0,
    transition: {
      type: "ease",
      when: "beforeChildren",
    },
  },
  closed: {
    y: "-100%",
    transition: {
      type: "ease",
    },
  },
};

const mobileNavItemVariants = {
  initial: {
    gap: "3rem",
  },
  open: {
    y: 0,
    opacity: 1,
    gap: "4.5rem",
    transition: {
      duration: 0.8,
      delay: 0.5,
      staggerChildren: 0.9,
      opacity: { duration: 0.9 },
    },
  },
  closed: {
    y: "20%",
    gap: "3.5rem",
    opacity: 0,
  },
};

const backgroundVariants = {
  initial: {
    x: "100%",
    opacity: 0,
  },
  open: {
    x: 0,
    opacity: 0.4,
    transition: {
      duration: 0.9,
      opacity: { duration: 0.5 },
    },
  },
  closed: {
    x: "100%",
    opacity: 0,
    transition: {
      duration: 0.9,
    },
  },
  exit: {
    y: "-100%",
    opacity: 0,
    transition: {
      type: "ease",
    },
  },
};

// const SeriesToggle = ({
//   isAmateur,
//   isNavOpen,
//   setIsNavOpen,
// }: {
//   isAmateur: boolean;
//   isNavOpen: boolean;
//   setIsNavOpen: (open: boolean) => void;
// }) => {
//   return (
//     <div className={styles.seriesToggle}>
//       <Link href="/">
//         <a
//           className={`${styles.toggleItem}${
//             !isAmateur ? " " + styles.toggleItemSelected : ""
//           }`}
//           onClick={() => {
//             isNavOpen && setIsNavOpen(false);
//           }}
//         >
//           Pro
//         </a>
//       </Link>
//       <Link href="/amateur">
//         <a
//           className={`${styles.toggleItem}${
//             isAmateur ? " " + styles.toggleItemSelected : ""
//           }`}
//           onClick={() => {
//             isNavOpen && setIsNavOpen(false);
//           }}
//         >
//           Amateur
//         </a>
//       </Link>
//     </div>
//   );
// };

const NavItem = ({
  navLink,
  isNavOpen,
  setIsNavOpen,
}: {
  navLink: { slug: string; name: string };
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
}) => {
  const { asPath } = useRouter();
  return (
    <Link key={navLink.slug} href={navLink.slug} passHref>
      <motion.a
        className={`${styles.navItem}${
          navLink.slug === asPath ? " " + styles.navItemActive : ""
        }${navLink.slug === "/join" ? " " + styles.navItemAction : ""}`}
        onClick={() => {
          isNavOpen && setIsNavOpen(false);
        }}
      >
        <span>{navLink.name}</span>
        <span className={styles.navItemBorder}>
          <span className={styles.borderLeft}>
            <span className={styles.border} />
          </span>
          <span className={styles.borderRight}>
            <span className={styles.border} />
          </span>
        </span>
      </motion.a>
    </Link>
  );
};

const Layout = ({
  children,
  navLinks,
  amateurNavLinks,
  theme,
}: LayoutProps) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isTablet, isDesktop } = useWindowSize();
  const { pathname } = useRouter();

  const isAmateur = theme === "amateur";
  const logoSrc = isAmateur
    ? "/icons/nextgolftour_logo_amateur.svg"
    : "/icons/nextgolftour_logo.svg";
  const logoUrl = isAmateur ? "/amateur" : "/";

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (isNavOpen) {
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.position = "static";
      }
    }
  }, [isNavOpen]);

  return (
    <>
      <header>
        <GridWrapper className={styles.navGrid}>
          <section className={styles.navContainer}>
            <div className={styles.navHeader}>
              <Link href={logoUrl}>
                <a
                  onClick={() => {
                    isNavOpen && setIsNavOpen(false);
                  }}
                >
                  <div className={styles.navLogoContainer}>
                    <Image
                      src={logoSrc}
                      alt="Next golf tour logo"
                      layout="fixed"
                      width={105}
                      height={45}
                      objectFit="contain"
                      priority
                    />
                  </div>
                </a>
              </Link>

              {!isDesktop && (
                <Hamburger
                  toggled={isNavOpen}
                  toggle={setIsNavOpen}
                  size={40}
                />
              )}
            </div>

            <AnimatePresence>
              {((!isDesktop && isNavOpen) || isDesktop) && (
                <>
                  <motion.div
                    className={styles.mainNav}
                    variants={mobileNavVariants}
                    animate={
                      isNavOpen && !isDesktop
                        ? "open"
                        : isDesktop
                        ? "initial"
                        : "closed"
                    }
                    initial={isDesktop ? "initial" : "closed"}
                    exit="closed"
                  >
                    <motion.nav
                      className={styles.mainNavItemWrapper}
                      variants={mobileNavItemVariants}
                      animate={
                        isNavOpen && !isDesktop
                          ? "open"
                          : isDesktop
                          ? "initial"
                          : "closed"
                      }
                      initial={isDesktop ? "initial" : "closed"}
                      exit="closed"
                    >
                      {/* <SeriesToggle
                    isAmateur={isAmateur}
                    isNavOpen={isNavOpen}
                    setIsNavOpen={setIsNavOpen}
                  /> */}
                      {isAmateur
                        ? amateurNavLinks.map((l) => {
                            return (
                              <NavItem
                                key={l.slug}
                                navLink={l}
                                isNavOpen={isNavOpen}
                                setIsNavOpen={setIsNavOpen}
                              />
                            );
                          })
                        : navLinks.map((l) => {
                            return (
                              <NavItem
                                key={l.slug}
                                navLink={l}
                                isNavOpen={isNavOpen}
                                setIsNavOpen={setIsNavOpen}
                              />
                            );
                          })}
                    </motion.nav>
                  </motion.div>

                  <motion.div
                    variants={backgroundVariants}
                    initial="initial"
                    animate={isNavOpen && isTablet ? "open" : "closed"}
                    exit="exit"
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: "var(--tm-c-dark)",
                      zIndex: 1,
                    }}
                  />
                </>
              )}
            </AnimatePresence>
          </section>
        </GridWrapper>
      </header>
      {children}
      <SpeedInsights route={pathname} />
      <footer className={styles.footer} style={{ marginTop: "auto" }}>
        <GridWrapper>
          {/* <div className={styles.seriesToggleWrapper}>
            <Link href={logoUrl}>
              <a>
                <div className={styles.navLogoContainer}>
                  <Image
                    src={logoSrc}
                    alt="Next golf tour logo"
                    layout="fixed"
                    width={105}
                    height={45}
                    objectFit="contain"
                    priority
                  />
                </div>
              </a>
            </Link>
            <SeriesToggle
              isAmateur={isAmateur}
              isNavOpen={isNavOpen}
              setIsNavOpen={setIsNavOpen}
            />
          </div> */}
          <nav className={styles.footerNav}>
            {isAmateur
              ? amateurNavLinks.map((l) => {
                  return (
                    <Link key={l.slug} href={l.slug}>
                      <a className={styles.navItem}>
                        <span>{l.name}</span>
                      </a>
                    </Link>
                  );
                })
              : navLinks.map((l) => {
                  return (
                    <Link key={l.slug} href={l.slug}>
                      <a className={styles.navItem}>
                        <span>{l.name}</span>
                      </a>
                    </Link>
                  );
                })}
            <a
              className={styles.navItem}
              href="https://trackmangolf.zendesk.com/hc/en-us/categories/11965023457179-Tournaments"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex", gap: "1rem" }}
            >
              <span>
                <HelpOutlineOutlinedIcon
                  style={{
                    fontSize: 22,
                  }}
                />
              </span>
              <span>Help Center</span>
            </a>
          </nav>
          <nav className={styles.socialNav}>
            <a
              className={styles.navItem}
              href="https://www.facebook.com/NEXTGolfTour"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon
                style={{
                  fontSize: 32,
                }}
              />
            </a>
            <a
              className={styles.navItem}
              href="https://www.instagram.com/nextgolftour"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                style={{
                  fontSize: 32,
                }}
              />
            </a>
            <a
              className={styles.navItem}
              href="https://twitter.com/NEXTGolfTour"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={twitterIcon}
                alt="twitter icon"
                width={25}
                height={25}
                objectFit="contain"
              />
            </a>
            <a
              className={styles.navItem}
              href="https://www.youtube.com/@NEXTGolfTour"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon
                style={{
                  fontSize: 32,
                }}
              />
            </a>
          </nav>
          <p className={styles.footerCopy}>
            Copyright &copy; {new Date().getFullYear()} NEXT Golf Tour. All
            rights reserved.
          </p>
        </GridWrapper>
      </footer>
    </>
  );
};

export default Layout;
